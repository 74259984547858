<div class="logout">
    <div class="buttons">
        <div class="back-button">
            <mat-icon (click)="onClickk()">arrow_back</mat-icon>
        </div>
    </div>
</div>

<div class="products-container">
    <div class="product-table">
        <table *ngFor="let orderDetail of orders">
            <div
                [ngStyle]="{
                    display: 'flex',
                    width: '100%',
                    background: 'lightgrey',
                    'margin-top': '8px',
                    'border-left': '1px solid #ccc',
                    'border-right': '1px solid #ccc',
                    'border-top': '1px solid #ccc'
                }"
            >
                <b [ngStyle]="{ margin: '8px', 'font-size': '14px' }">{{ orderDetail.orderDetailReference }}</b>
                <div
                    [ngStyle]="{
                        display: 'flex',
                        width: '100%',
                        background: 'lightgrey',
                        'border-left': '1px solid #ccc',
                    }"
                    class="field-container"
                >
                    <div [ngStyle]="{ display: 'flex', width: '100%' }" class="field">
                        <div [ngStyle]="{ 'margin-top': '8px' }" class="data">
                            <mat-form-field *ngIf="orderDetail.deliveryOption !== 'STORE_PICKUP'">
                                <mat-select *ngIf="orderDetail.status !== 'BLOCKED'" [(ngModel)]="orderDetail.status" id="statuses">
                                    <mat-option
                                        (click)="loadConfirmOrderStatusPopup(orderDetail, '500ms', '200ms')"
                                        *ngFor="let orderStatus of orderStatusOptionList; let i = index"
                                        [value]="orderStatus"
                                        [disabled]="
                                            orderStatus === 'WAITING_FOR_PAYMENT' ||
                                            orderStatus === 'PAYMENT_FAILED' ||
                                            orderStatus === 'WAITING_FOR_PROCESSING' ||
                                            orderStatus === 'BLOCKED' ||
                                            orderStatus === 'WAITING_FOR_PUBLICATION'
                                        "
                                    >
                                        {{ 'admin.' + orderStatus | translate | uppercase }}
                                    </mat-option>
                                </mat-select>
                                <mat-select *ngIf="orderDetail.status === 'BLOCKED'" [(ngModel)]="orderDetail.status" id="statuses">
                                    <mat-option
                                        (click)="loadConfirmOrderStatusPopup(orderDetail, '500ms', '200ms')"
                                        [value]="orderDetail.status"
                                        [disabled]="orderDetail.status === 'BLOCKED'"
                                    >
                                        {{ 'admin.' + orderDetail.status | translate | uppercase }}
                                    </mat-option>
                                    <mat-option (click)="loadConfirmOrderStatusPopup(orderDetail, '500ms', '200ms')" [value]="'DELETED'">
                                        {{ 'admin.' + 'DELETED' | translate | uppercase }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field *ngIf="orderDetail.deliveryOption === 'STORE_PICKUP'">
                                <mat-select *ngIf="orderDetail.status !== 'BLOCKED'" [(ngModel)]="orderDetail.status" id="statuses">
                                    <mat-option
                                        (click)="loadConfirmOrderStatusPopup(orderDetail, '500ms', '200ms')"
                                        *ngFor="let orderStatus of orderStatusStorePickupOptionList; let i = index"
                                        [value]="orderStatus"
                                        [disabled]="
                                            orderStatus === 'WAITING_FOR_PAYMENT' ||
                                            orderStatus === 'PAYMENT_FAILED' ||
                                            orderStatus === 'WAITING_FOR_PROCESSING' ||
                                            orderStatus === 'BLOCKED' ||
                                            orderStatus === 'WAITING_FOR_PUBLICATION'
                                        "
                                    >
                                        {{ 'admin.' + orderStatus | translate | uppercase }}
                                    </mat-option>
                                </mat-select>
                                <mat-select *ngIf="orderDetail.status === 'BLOCKED'" [(ngModel)]="orderDetail.status" id="statuses">
                                    <mat-option
                                        (click)="loadConfirmOrderStatusPopup(orderDetail, '500ms', '200ms')"
                                        [value]="orderDetail.status"
                                        [disabled]="orderDetail.status === 'BLOCKED'"
                                    >
                                        {{ 'admin.' + orderDetail.status | translate | uppercase }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <tr>
                <th>Szerző</th>
                <th>Cím</th>
                <th>Rendelési mennyiség</th>
                <th>Csomag neve</th>
                <th>Ár</th>
                <th>Várható megjelenés</th>
            </tr>
            <ng-container>
                <tr *ngFor="let product of orderDetail.storeOrderedProductList">
                    <td>{{ product.author }}</td>
                    <td>{{ product.title }}</td>
                    <td>{{ product.quantity }}</td>
                    <td *ngIf="product.packageName">{{ product.packageName }}</td>
                    <td [ngStyle]="{ 'text-align': 'center' }" *ngIf="!product.packageName">-</td>
                    <td>{{ product.quantity }}db - {{ product.price | currency : 'Ft' : 'symbol' : '1.0-0' }}</td>
                    <td [ngStyle]="{ color: 'red', 'font-weight': 'bold' }" *ngIf="product.hasPreorder">
                        {{ product.available | date }}
                    </td>
                    <td [ngStyle]="{ 'text-align': 'center' }" *ngIf="!product.hasPreorder">Megjelent</td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
<div class="search-bar-container">
    <div class="search-bar">
        <input [(ngModel)]="listFilter" type="search" placeholder="Rendelési azonosító,név,email,dátum,státusz..." />
    </div>
</div>
<mat-radio-group
    *ngIf="!isProductScreenVisible && !isAllOrderProductsVisible"
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    [(ngModel)]="typeOfOrder"
>
    <div class="mat-radio-container">
        <mat-radio-button (click)="unfinished()" class="example-radio-button" value="UNFINISHED">
            <h4>Befejezetlen rendelések</h4>
        </mat-radio-button>
        <mat-radio-button (click)="allOrders()" class="example-radio-button" value="ALLORDERS">
            <h4>Összes rendelés</h4>
        </mat-radio-button>
        <mat-radio-button (click)="finished()" class="example-radio-button" value="FINISHED">
            <h4>Teljesített rendelések</h4>
        </mat-radio-button>
        <mat-radio-button (click)="preorders()" class="example-radio-button" value="PREORDER">
            <h4>Előrendelések</h4>
        </mat-radio-button>
    </div>
    <div class="export-to-excel-container">
        <div class="export-to-excel">
            <button (click)="sendDataToGenerateExcel()">Rendelések exportálás excelbe</button>
        </div>
    </div>
</mat-radio-group>

<div *ngIf="!isProductScreenVisible && !isAllOrderProductsVisible" class="admin-table-container">
    <div
        [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
        *ngFor="let order of filteredOrder; let i = index"
        class="admin-table"
    >
        <div *ngIf="order.order?.orderReference" class="field-container">
            <div class="field">
                <span>Rendelési azonosító</span>
                <div class="data">
                    <span [ngStyle]="{ 'font-weight': 'bold' }">{{ order.order?.orderReference! }}</span>
                    <div [ngStyle]="{ color: 'red' }" *ngIf="order.status === 'FINISHED'">RENDELÉS TELJESÍTVE!!!</div>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div id="{{ order.orderDetailReference! }}" class="field">
                <span>Rendelési alazonosító</span>
                <div class="data">
                    <span>{{ order.orderDetailReference }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Rendelés típusa</span>
                <div class="data">
                    <span [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">{{ 'order.' + order.preOrder | translate }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="order.preOrder" class="field-container">
            <div class="field">
                <span>Rendelés összevárása</span>
                <div class="data">
                    <span [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">{{ 'separated.' + order.order?.separated | translate }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Rendelés dátuma</span>
                <div class="data">
                    <span> {{ order.insertedTimestamp | date : 'yyyy, MMM dd, hh:mm a' }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Email cím</span>
                <div class="data">
                    <span>{{ order.customerEmail }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Név</span>
                <div class="data">
                    <span>{{ order.lastName }} {{ order.firstName }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="order.companyName" class="field-container">
            <div class="field">
                <span>Cégnév</span>
                <div class="data">
                    <span>{{ order.companyName }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="order.taxNumber" class="field-container">
            <div class="field">
                <span>Adószám</span>
                <div class="data">
                    <span>{{ order.taxNumber }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Telefonszám</span>
                <div class="data">
                    <span>{{ order.customerPhone }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Szállítás</span>
                <div class="data">
                    <span *ngIf="order.deliveryOption !== 'STORE_PICKUP'">{{ order.deliveryOption }}</span>
                    <span *ngIf="order.deliveryOption === 'STORE_PICKUP'">{{
                        'admin.' + order.deliveryOption | translate | uppercase
                    }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Számlázási cím</span>
                <div class="data">
                    <span>{{ order.customerZipCode + ' ' + order.customerSettlement + ' ' + order.customerAddress }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="order.deliveryOption !== 'STORE_PICKUP'" class="field-container">
            <div class="field">
                <span>Szállítási cím</span>
                <div *ngIf="order.deliveryOption !== 'FOXPOST'" class="data">
                    <span>{{ order.deliveryZipCode + ' ' + order.deliverySettlement + ' ' + order.deliveryAddress }}</span>
                </div>
                <div *ngIf="order.deliveryOption === 'FOXPOST'" class="data">
                    <span>{{ order.deliveryAddress }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Kifizetés státusza</span>
                <div class="data">
                    <b *ngIf="order.failedPaymentMessageKey !== null" [ngStyle]="{ color: 'red' }">{{
                        'payment.' + order.failedPaymentMessageKey | translate
                    }}</b>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Végösszeg</span>
                <div class="data">
                    <b>{{ order.totalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</b>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Státusz választó</span>
                <div class="data">
                    <mat-form-field *ngIf="order.deliveryOption !== 'STORE_PICKUP'">
                        <mat-select *ngIf="order.status !== 'BLOCKED'" [(ngModel)]="order.status" id="statuses">
                            <mat-option
                                (click)="loadConfirmOrderStatusPopup(order, '500ms', '200ms')"
                                *ngFor="let orderStatus of orderStatusOptionList; let i = index"
                                [value]="orderStatus"
                                [disabled]="
                                    orderStatus === 'WAITING_FOR_PAYMENT' ||
                                    orderStatus === 'PAYMENT_FAILED' ||
                                    orderStatus === 'WAITING_FOR_PROCESSING' ||
                                    orderStatus === 'BLOCKED' ||
                                    orderStatus === 'WAITING_FOR_PUBLICATION'
                                "
                            >
                                {{ 'admin.' + orderStatus | translate | uppercase }}
                            </mat-option>
                        </mat-select>
                        <mat-select *ngIf="order.status === 'BLOCKED'" [(ngModel)]="order.status" id="statuses">
                            <mat-option
                                (click)="loadConfirmOrderStatusPopup(order, '500ms', '200ms')"
                                [value]="order.status"
                                [disabled]="order.status === 'BLOCKED'"
                            >
                                {{ 'admin.' + order.status | translate | uppercase }}
                            </mat-option>
                            <mat-option (click)="loadConfirmOrderStatusPopup(order, '500ms', '200ms')" [value]="'DELETED'">
                                {{ 'admin.' + 'DELETED' | translate | uppercase }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="order.deliveryOption === 'STORE_PICKUP'">
                        <mat-select *ngIf="order.status !== 'BLOCKED'" [(ngModel)]="order.status" id="statuses">
                            <mat-option
                                (click)="loadConfirmOrderStatusPopup(order, '500ms', '200ms')"
                                *ngFor="let orderStatus of orderStatusStorePickupOptionList; let i = index"
                                [value]="orderStatus"
                                [disabled]="
                                    orderStatus === 'WAITING_FOR_PAYMENT' ||
                                    orderStatus === 'PAYMENT_FAILED' ||
                                    orderStatus === 'WAITING_FOR_PROCESSING' ||
                                    orderStatus === 'BLOCKED' ||
                                    orderStatus === 'WAITING_FOR_PUBLICATION'
                                "
                            >
                                {{ 'admin.' + orderStatus | translate | uppercase }}
                            </mat-option>
                        </mat-select>
                        <mat-select *ngIf="order.status === 'BLOCKED'" [(ngModel)]="order.status" id="statuses">
                            <mat-option
                                (click)="loadConfirmOrderStatusPopup(order, '500ms', '200ms')"
                                [value]="order.status"
                                [disabled]="order.status === 'BLOCKED'"
                            >
                                {{ 'admin.' + order.status | translate | uppercase }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
