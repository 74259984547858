import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import localeHu from '@angular/common/locales/hu';
import localeHuExtra from '@angular/common/locales/extra/hu';
import { registerLocaleData } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { HomeConfigModule, TranslationModule, SharedModule, HeadermoduleModule, LanguageModule, ToastmessageModule, NavigationModule, BannersModule,HtmleditorModule, BlogModule, FilemanagerModule, TinymcehtmleditorModule, FilterModule, SiteconfigModule, ColleauguesModule} from '@prekog/pw-common';
import { OrderComponent } from './order/order.component';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { ConfirmStatusPopupComponent } from './confirm-status-popup/confirm-status-popup.component';
import { RegisteredcustomersComponent } from './registeredcustomers/registeredcustomers.component';
import { PreordersComponent } from './preorders/preorders.component';
import { AddproductComponent } from './products/addproduct/addproduct.component';
import { PackagesComponent } from './packages/packages.component';
import { UpdatepackageComponent } from './packages/updatepackage/updatepackage.component';
import { SeriesComponent } from './series/series.component';
import { AddserieComponent } from './series/addserie/addserie.component';
import { UpdateserieComponent } from './series/updateserie/updateserie.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UpdatepackageproductsComponent } from './packages/updatepackageproducts/updatepackageproducts.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AddproductstoserieComponent } from './series/addproductstoserie/addproductstoserie.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ConfirmdeleteproductfromseriepopupcomponentComponent } from './series/addproductstoserie/confirmdeleteproductfromseriepopupcomponent/confirmdeleteproductfromseriepopupcomponent.component';
import { OnsaleperiodsComponent } from './onsaleperiods/onsaleperiods.component';
import { UpdateonsaleComponent } from './onsaleperiods/updateonsale/updateonsale.component';
import { ConfirmsetpreorderpublishedpopupComponent } from './products/updateproduct/confirmsetpreorderpublishedpopup/confirmsetpreorderpublishedpopup.component';
import { ProductsComponent } from './products/products.component';
import { UpdateproductComponent } from './products/updateproduct/updateproduct.component';
import { AddpackageComponent } from './packages/addpackage/addpackage.component';
import { AddproductstopackageComponent } from './packages/addproductstopackage/addproductstopackage.component';
import { AddpackagepricepopupComponent } from './packages/addpackage/addpackagepricepopup/addpackagepricepopup.component';
import { ProductsaleComponent } from './onsaleperiods/addproductsonsale/addproductsonsale.component';
import { AddDiscountpricepopupComponent } from './onsaleperiods/addproductsonsale/adddiscountpricepopup/adddiscountpricepopup.component';
import { GetordersComponent } from './order/getorders/getorders.component';
import { GetorderdetailsbyorderidComponent } from './order/getorderdetailsbyorderid/getorderdetailsbyorderid.component';
import { NgxEditorModule } from 'ngx-editor';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RemovepackagepopupComponent } from './packages/removepackagepopup/removepackagepopup.component';
import { FilterComponent } from './order/getorders/filter/filter.component';
import { NewsletterComponent } from './newsletter/newsletter.component';


registerLocaleData(localeHu, 'hu-Hu', localeHuExtra);

@NgModule({
    declarations: [
        AppComponent,
        OrderComponent,
        ConfirmStatusPopupComponent,
        RegisteredcustomersComponent,
        PreordersComponent,
        AddproductComponent,
        PackagesComponent,
        UpdateproductComponent,
        UpdatepackageComponent,
        AddpackageComponent,
        SeriesComponent,
        AddserieComponent,
        UpdateserieComponent,
        AddproductstopackageComponent,
        UpdatepackageproductsComponent,
        AddproductstoserieComponent,
        ConfirmdeleteproductfromseriepopupcomponentComponent,
        ProductsaleComponent,
        OnsaleperiodsComponent,
        UpdateonsaleComponent,
        AddpackagepricepopupComponent,
        AddDiscountpricepopupComponent,
        ConfirmsetpreorderpublishedpopupComponent,
        ProductsComponent,
        GetordersComponent,
        GetorderdetailsbyorderidComponent,
        RemovepackagepopupComponent,
        FilterComponent,
        NewsletterComponent



    ],
    providers: [
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true }
        },
        {
            provide: LOCALE_ID,
            useValue: 'hu-HU'
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'black' }
        },
        { provide: 'ORDER_BACKEND_URL', useValue: environment.backendOrderUrl },
        { provide: 'STORE_BACKEND_URL', useValue: environment.backendStoreUrl },
        { provide: 'AUTH_BACKEND_URL', useValue: environment.backendAuthUrl },
        { provide: 'CONFIGURATOR_BASE_URL', useValue: environment.backendConfiguratorUrl },
        { provide: 'API_URL', useValue: environment.apiUrl },
        { provide: 'DOWNLOAD_FILE_URL', useValue: environment.downloadUrl },
        { provide: 'FILE_MANAGER_URL', useValue: environment.backendFileManagerUrl },
        { provide: 'EMAIL_BACKEND_URL', useValue: environment.backendEmailUrl },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} }
    ],

    bootstrap: [AppComponent],
    imports: [
        ReactiveFormsModule,
        BrowserModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatDividerModule,
        MatDialogModule,
        MatSelectModule,
        MatMenuModule,
        MatRadioModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        DragDropModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        TranslationModule.forRoot({ translatedLanguages: ['hu'] }),
        SharedModule.forRoot(),
        HeadermoduleModule,
        LanguageModule.forRoot(),
        ToastmessageModule,
        NavigationModule.forRoot(),
        NgxEditorModule,
        BannersModule,
        ClipboardModule,
        HtmleditorModule,
        HomeConfigModule,
        BlogModule,
        FilemanagerModule,
        TinymcehtmleditorModule,
        FilterModule,
        SiteconfigModule,
        ColleauguesModule


    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
