export enum StatusOption {
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    WAITING_FOR_PROCESSING = 'WAITING_FOR_PROCESSING',
    UNDER_PACKING = 'UNDER_PACKING',
    UNDER_DELIVERY = 'UNDER_DELIVERY',
    RECEIVABLE = 'RECEIVABLE',
    FINISHED = 'FINISHED',
    DELETED = 'DELETED',
    BLOCKED = 'BLOCKED',
    WAITING_FOR_PUBLICATION = 'WAITING_FOR_PUBLICATION',
    ALL = 'ALL',
    UNFINISHED = 'UNFINISHED',
    PREORDER = 'PREORDER'
}
