import { FilterOrdersRequest } from './../filterOrdersRequest';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { StatusOption } from '../../statusoption';
import { OrderDate } from '../orderdate';
import { DeliveryOption } from '../deliveryoption';
import { PaymentStatus } from '../paymentstatus';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { GetProductsResponse } from '../../../products/getproductsresponse';
import { Material } from '../../../products/material';
import { OrderWithDate } from '../orderwithdate';
import { FilterOrdersResponse } from '../filterOrdersResponse';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

    selectedAlign: 'start' | 'center' | 'end';

    orderBackendUrl = '';

    filteredOrder: OrderWithDate[] = [];

    orderFilterUrl = '/admin/filter-order';


    ordersAccordingToStatuses = [
        StatusOption.ALL,
        StatusOption.FINISHED,
        StatusOption.UNFINISHED,
        StatusOption.PREORDER
    ]
    ordersType = [
        OrderDate.LAST_TWO_MONTHES,
        OrderDate.LAST_MONTH
    ]
    ordersAccordingTodeliveryOptions = [
        DeliveryOption.FOXPOST,
        DeliveryOption.STORE_PICKUP,
        DeliveryOption.MPL
    ]

    ordersAccordingToOrderDate = [
        OrderDate.LAST_TWO_MONTHES,
        OrderDate.LAST_MONTH
    ]

    ordersAccordingToPaymentStatus = [
        PaymentStatus.SUCCESSFUL,
        PaymentStatus.PAY_ON_STORE,
        PaymentStatus.FAIL
    ]

    isPreorder = false;

    status = StatusOption.ALL;

    deliveryOption = DeliveryOption.FOXPOST;

    orderDate = OrderDate.LAST_TWO_MONTHES;

    productId = 0;

    paymentStatus = PaymentStatus.SUCCESSFUL;

    storeBaseUrl = '';

    storeExistingProductsURI = '/admin/get-products';

    products: Material[] = [];

    constructor(
        //@Inject(MAT_DIALOG_DATA) public order: OrderDetails,
        private _dialog: MatDialog,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _translateService: TranslationService,
        private _router: Router,
        private _toastMessageService: ToastmessageService
    ) {
        this.selectedAlign = 'end';
        this.orderBackendUrl = _orderBackendUrl;
        this.storeBaseUrl = _storeBaseUrl;
    }
    ngOnInit(): void {
        this.getProducts();
    }

    getProducts() {
        this._apiRequestService.sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage), MAT_ICON.error);
                        this.dismissError();


                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                const products = response as GetProductsResponse;
                this.products = products.productList as Material[];
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    filter() {
        const FilterOrdersRequest: FilterOrdersRequest = {
            orderStatus: this.status,
            deliveryOption: this.deliveryOption,
            timeInterval: this.orderDate,
            productId: this.productId,
            failedPaymentMessageKey: this.paymentStatus
        }

        this._apiRequestService
            .sendRequest<FilterOrdersRequest, FilterOrdersResponse>(this.orderBackendUrl, true, FilterOrdersRequest, true, this.orderFilterUrl)
            .subscribe((response) => {
                this.filteredOrder = response.filteredOrderList as OrderWithDate[];
                if (this.filteredOrder.length > 0) {
                    sessionStorage.setItem('filteredOrder', JSON.stringify(this.filteredOrder));
                }
                this._dialog.closeAll();
                window.location.reload();
            });
    }
}
