import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { GetOnSalePeriodRequest } from './getonsaleperiodrequest';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService, HttpError, MatdialogService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { GetOnSalePeriodResponse } from './getonsaleperiodresponse';
import { OnSalePeriod } from '../addproductsonsale/onsaleperiod';
import { GetProductsResponse } from '../../products/getproductsresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateOnSalePeriodRequest } from './updateonsaleperiodrequest';
import { UpdateOnSalePeriodResponse } from './updateonsaleperiodresponse';
import { DeleteOnSalePeriodProductsRequest } from './deleteonsaleperiodproductsrequest';
import { DeleteOnSalePeriodProductsResponse } from './deleteonsaleperiodproductsresponse';
import { AddProductsOnSaleRequest } from '../addproductsonsale/addproductsonsalerequest';
import { AddProductsOnSaleResponse } from '../addproductsonsale/addproductsonsaleresponse';
import { ProductsOnSale } from '../addproductsonsale/productsonsale';
import { GetProductsForOnSalePeriodRequest } from './getproductsforonsaleperiodrequest';
import { GetProductsForOnSalePeriodResponse } from './getproductsforonsaleperiodresponse';
import { AddDiscountpricepopupComponent } from '../addproductsonsale/adddiscountpricepopup/adddiscountpricepopup.component';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Material } from '../../products/material';

@Component({
    selector: 'app-updateonsale',
    templateUrl: './updateonsale.component.html',
    styleUrls: ['./updateonsale.component.scss']
})
export class UpdateonsaleComponent implements OnInit {
    getOnSalePeriodUrl = '/admin/get-on-sale-period';

    getOnSalePeriodRequest: GetOnSalePeriodRequest = {};

    storeBaseUrl = '';

    onSalePeriod: OnSalePeriod = {};

    saleForm?: FormGroup;

    filteredBooks: Material[] = [];

    products: Material[] = [];

    storeExistingProductsURI = '/store/get-products';

    getProductsForOnSalePeriodUrl = '/admin/get-on-sale-products';

    updateOnSalePeriodUrl = '/admin/update-on-sale-period';

    deleteOnSalePeriodProductsUrl = '/admin/delete-on-sale-period-products';

    updateableProducts: Material[] = [];

    getProductsForOnSalePeriodRequest: GetProductsForOnSalePeriodRequest = {};

    updateOnSalePeriodRequest: UpdateOnSalePeriodRequest = {};

    deleteOnSaleProductsRequest: DeleteOnSalePeriodProductsRequest = {};

    addProductsOnSaleUrl = '/admin/add-products-on-sale';

    addProductsOnSaleRequest: AddProductsOnSaleRequest = {};

    startDate = '';

    endDate = '';

    searchText = '';

    constructor(
        public _router: Router,
        private _fb: FormBuilder,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _toastMessageService: ToastmessageService,
        private _translateService: TranslationService,
        private _matdialogService:MatdialogService
    ) {
        this.storeBaseUrl = _storeBaseUrl;
        this.createSaleForm();
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this.onInitData();
        } else {
            this._router.navigateByUrl('/');
        }
    }

    onInitData() {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        this.getOnSalePeriodRequest = {
            routerLink: routerLink
        };

        this._apiRequestService
            .sendRequest<GetOnSalePeriodRequest, GetOnSalePeriodResponse>(
                this.storeBaseUrl,
                true,
                this.getOnSalePeriodRequest,
                true,
                this.getOnSalePeriodUrl
            )
            .subscribe((response) => {
                this.onSalePeriod = response.onSalePeriod as OnSalePeriod;
                this.startDate = this.onSalePeriod.startDate as string;
                this.endDate = this.onSalePeriod.endDate as string;
                this.getProductsForOnSalePeriod(this.onSalePeriod.routerLink!);
            });
    }

    getProductsForOnSalePeriod(routerLink: string) {
        this.getProductsForOnSalePeriodRequest = {
            routerLink: routerLink
        };

        this._apiRequestService
            .sendRequest<GetProductsForOnSalePeriodRequest, GetProductsForOnSalePeriodResponse>(
                this.storeBaseUrl,
                true,
                this.getProductsForOnSalePeriodRequest,
                true,
                this.getProductsForOnSalePeriodUrl
            )
            .subscribe((response) => {
                this.updateableProducts = response.products as Material[];
                this.getProducts();
            });
    }

    getProducts() {
        this._apiRequestService
            .sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                const products = response as GetProductsResponse;
                this.products = products.productList as Material[];
                products.productList!.forEach((product) => {
                    if (!product.isAvailable) {
                        const index = this.products.indexOf(product);
                        this.products.splice(index, 1);
                    }
                });

                //skipping same elements with updateAbleProducts from products
                this.products = this.products.filter(
                    (p) => !this.updateableProducts.some((p2) => p2.routerLink === p.routerLink)
                ) as Material[];

                this.filteredBooks = this.products;
                if (sessionStorage.getItem('product')) {
                    const product = JSON.parse(sessionStorage.getItem('product') as string) as Material;
                    const index = this.products.indexOf(product);
                    this.products.splice(index, 1);
                    this.updateableProducts.push(product);
                    sessionStorage.removeItem('product');
                }
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
    private dismissErrorFiveSeconds(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 5000);
    }

    createSaleForm() {
        this.saleForm = this._fb.group({
            onSaleEndDate: ['', [Validators.required]],
            onSaleStartDate: ['', [Validators.required]],
            onSaleName: ['', [Validators.required]]
        });
    }

    collectOnSalePeriodData() {
        this.onSalePeriod.name = this.saleForm?.controls['onSaleName'].value as string | undefined;
        this.onSalePeriod.startDate = this.startDate;
        this.onSalePeriod.endDate = this.endDate;
    }

    addProductToOnSale(product: Material) {
        if (!product.discountPrice) {
            this.openDiscountPricePopup(product);
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('Kérem adja meg az akciós a ' + product.author + ':' + product.title + ' könyvhöz!'),
                MAT_ICON.error
            );
            this.dismissErrorFiveSeconds();
        } else {
            const index = this.products.indexOf(product);
            this.products.splice(index, 1);
            this.updateableProducts.push(product);
        }
    }

    deleteFromOnSaleProductList(product: Material) {
        const index = this.updateableProducts.indexOf(product);
        this.updateableProducts.splice(index, 1);
        this.products.push(product);
    }

    updateOnSalePeriod() {
        this.collectOnSalePeriodData();

        this.updateOnSalePeriodRequest = {
            onSalePeriod: this.onSalePeriod
        };

        this._apiRequestService
            .sendRequest<UpdateOnSalePeriodRequest, UpdateOnSalePeriodResponse>(
                this.storeBaseUrl,
                true,
                this.updateOnSalePeriodRequest,
                true,
                this.updateOnSalePeriodUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                this.deleteProducts();
            });
    }

    deleteProducts() {
        this.deleteOnSaleProductsRequest = {
            onSalePeriodId: this.onSalePeriod.id
        };

        this._apiRequestService
            .sendRequest<DeleteOnSalePeriodProductsRequest, DeleteOnSalePeriodProductsResponse>(
                this.storeBaseUrl,
                true,
                this.deleteOnSaleProductsRequest,
                true,
                this.deleteOnSalePeriodProductsUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((responseMessage) => {
                this.addProducts();
            });
    }

    addProducts() {
        let productsOnSale: ProductsOnSale[] = [];
        this.updateableProducts.forEach((updateableProduct) => {
            const productsSale: ProductsOnSale = new ProductsOnSale();
            productsSale.productId = updateableProduct.id;
            productsSale.onSalePeriod = this.onSalePeriod;
            productsOnSale.push(productsSale);
        });

        this.addProductsOnSaleRequest = {
            productOnSaleList: productsOnSale
        };
        this._apiRequestService
            .sendRequest<AddProductsOnSaleRequest, AddProductsOnSaleResponse>(
                this.storeBaseUrl,
                true,
                this.addProductsOnSaleRequest,
                true,
                this.addProductsOnSaleUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((responseMessage) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, responseMessage.responseMessage!, MAT_ICON.success);
                this.dismissError();
            });
    }

    onChangeStartDate(event: Event) {
        const target = event.target as HTMLInputElement;

        this.startDate = target.value;

        console.log(target.value);
    }

    onChangeEndDate(event: Event) {
        const target = event.target as HTMLInputElement;

        this.endDate = target.value;
    }
    openDiscountPricePopup(product: Material) {
        this._matdialogService.openPopupWithData(product,AddDiscountpricepopupComponent);
    }
}
