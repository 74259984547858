<div class="products">
    <div class="title">
        <div class="tit">
            <h3>RENDELÉSEK</h3>
        </div>
    </div>
    <button [ngStyle]="{ 'margin-bottom': '8px' }" (click)="scrollToFragment()">Vissza az előző rendeléshez!</button>
    <div class="add-new-product">
        <div class="filter-container">
            <div class="filter">
                <div class="filter-elements">
                    <div class="select-container">
                        <span for="statusOption">RENDELÉS ÁLLAPOTA SZERINT</span>
                        <select
                            [(ngModel)]="status"
                            [ngStyle]="{ 'font-size': '12px', cursor: 'pointer' }"
                            name="statusOption"
                            id="statusOption"
                            placeholder="Válasszon státuszt!"
                            (change)="filter()"
                        >
                            <option *ngFor="let statusOption of ordersAccordingToStatuses" [value]="statusOption">
                                {{ 'admin.' + statusOption | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="select-container">
                        <span for="statusOption">SZÁLLÍTÁSI MÓD SZERINT</span>
                        <select
                            (change)="filter()"
                            [(ngModel)]="deliveryOption"
                            [ngStyle]="{ 'font-size': '12px', cursor: 'pointer' }"
                            name="cars"
                            id="cars"
                        >
                            <option *ngFor="let deliveryOption of ordersAccordingTodeliveryOptions" [value]="deliveryOption">
                                {{ 'admin.' + deliveryOption | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="select-container">
                        <span for="statusOption">MEGRENDELÉS DÁTUMA SZERINT</span>
                        <select
                            (change)="filter()"
                            [(ngModel)]="orderDate"
                            [ngStyle]="{ 'font-size': '12px', cursor: 'pointer' }"
                            name="cars"
                            id="cars"
                        >
                            <option *ngFor="let orderDate of ordersAccordingToOrderDate" [value]="orderDate">
                                {{ 'admin.' + orderDate | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="select-container">
                        <span for="statusOption">KIADVÁNY SZERINT</span>
                        <select
                            (change)="filter()"
                            [(ngModel)]="productId"
                            [ngStyle]="{ 'font-size': '12px', cursor: 'pointer' }"
                            name="cars"
                            id="cars"
                        >
                            <option value="0">Összes kiadvány</option>
                            <option *ngFor="let product of products" [value]="product.id">
                                {{ product.author + ' ' + product.title }}
                            </option>
                        </select>
                    </div>
                    <div class="select-container">
                        <span for="statusOption">KIFIZETÉS STÁTUSZA SZERINT</span>
                        <select
                            (change)="filter()"
                            [(ngModel)]="paymentStatus"
                            [ngStyle]="{ 'font-size': '12px', cursor: 'pointer' }"
                            name="cars"
                            id="cars"
                        >
                            <option *ngFor="let paymentStatus of ordersAccordingToPaymentStatus" [value]="paymentStatus">
                                {{ 'admin.' + paymentStatus | translate }}
                            </option>
                        </select>
                    </div>
                    <div [ngStyle]="{ display: 'flex', 'justify-content': 'center' }" class="select-container">
                        <div class="all-orders"><span>Összes rendelés</span></div>
                        <mat-icon (click)="refreshPage()">refresh</mat-icon>
                    </div>
                </div>
            </div>
        </div>

        <div class="search-bar">
            <div class="search-tool">
                <input
                    [(ngModel)]="searchText"
                    type="search"
                    placeholder="Vezetéknév,keresztnév,Rendelési azonosító"
                    [disabled]="!isSearchAboutOrderReference"
                />
                <div class="search-button" [ngStyle]="{ display: 'flex', background: '#ccc', padding: '2px' }">
                    <mat-icon (click)="findOrderBy()">search</mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="Table">
        <div class="Heading">
            <div class="Cell heading">
                <p>DÁTUM</p>
            </div>
            <div class="Cell heading">
                <p>RENDELÉSI AZONOSÍTÓ</p>
            </div>
            <div class="Cell heading">
                <p>NÉV</p>
            </div>
            <div class="Cell heading">
                <p>EMAIL CÍM</p>
            </div>
            <div class="Cell heading">
                <p>ELŐRENDELÉS?</p>
            </div>
            <div class="Cell heading">
                <p>VÉGÖSSZEG</p>
            </div>
            <div class="Cell heading">
                <p>KIFIZETÉS STÁTUSZA</p>
            </div>
        </div>

        <div
            [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
            *ngFor="let order of filteredOrder | filter : searchText; let i = index"
            class="Row"
            (click)="findOrderDetailsByOrderId(order.id!)"
        >
            <div class="Cell">
                <span>{{ order.orderDate | date : 'yyyy MMM dd hh:mm' : '+0200' }}</span>
            </div>
            <div id="{{ order.orderReference! }}" class="Cell orderReference">
                <span [ngStyle]="{ 'font-weight': 'bold' }">{{ order.orderReference }}</span>
            </div>
            <div class="Cell">
                <span>{{ order.name }}</span>
            </div>
            <div class="Cell">
                <span>{{ order.email }}</span>
            </div>
            <div class="Cell">
                <span>{{ 'preorder.' + order.hasPreorder | translate }}</span>
            </div>
            <div class="Cell">
                <span>{{ order.totalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
            </div>
            <div class="Cell">
                <b *ngIf="order.paymentOption === 'SIMPLE' && order.failedPaymentMessageKey !== null" [ngStyle]="{ color: 'red' }">{{
                    'payment.' + order.failedPaymentMessageKey | translate
                }}</b>
                <b [ngStyle]="{ color: 'red' }" *ngIf="order.paymentOption === 'CASH' && order.failedPaymentMessageKey === null"
                    >HELYSZÍNI FIZETÉS</b
                >
            </div>
        </div>
    </div>
</div>

<!--mobile view-->
<div class="admin-table-container">
    <div
        [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
        *ngFor="let order of filteredOrder | filter : searchText; let i = index"
        class="admin-table"
    >
        <div class="field-container">
            <div class="field">
                <span>Rendelés dátuma</span>
                <div class="data">
                    <span>{{ order.orderDate | date : 'yyyy, MMM dd, hh:mm a' : '+0200' }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Rendelési azonosító</span>
                <div id="{{ order.orderReference! }}" class="data">
                    <span [ngStyle]="{ 'font-weight': 'bold' }">{{ order.orderReference }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Név</span>
                <div class="data">
                    <span>{{ order.name }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Email cím</span>
                <div class="data">
                    <span>{{ order.email }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Végösszeg</span>
                <div class="data">
                    <span>{{ order.totalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Kifizetés státusza</span>
                <div class="data">
                    <b *ngIf="order.failedPaymentMessageKey !== null" [ngStyle]="{ color: 'red' }">{{
                        'payment.' + order.failedPaymentMessageKey | translate
                    }}</b>
                    <b [ngStyle]="{ color: 'red' }" *ngIf="order.paymentOption === 'CASH' && order.failedPaymentMessageKey === null"
                        >HELYSZÍNI FIZETÉS</b
                    >
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Rendelési tételek</span>
                <div class="data">
                    <button routerLink="{{ order.id }}">Rendelési tételek</button>
                </div>
            </div>
        </div>
    </div>
</div>
