
import { Component, ElementRef, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { Product } from '../product.model';
import { StatusOption } from '../statusoption';
import { GetProductRequest } from '../get-product-request';
import { ExportToExcelRequest } from '../export-to-excel-request';
import { OrderExportToExcel } from '../order-export-to-excel';
import { SafeResourceUrl } from '@angular/platform-browser';
import { OrderWithStatusOption } from '../order-with-statusoption';
import { GetOrderReferenceRequest } from '../getorderreferencerequest';
import { GetOrderReferenceResponse } from '../getorderreferenceresponse';
import { ApiRequestService, ToastmessageService } from '@prekog/pw-common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { GetProductResponse } from '../get-product-response';
import { ConfirmStatusPopupComponent } from '../../confirm-status-popup/confirm-status-popup.component';
import { GetOrderDetailsByIdRequest } from './getorderdetailsbyidrequest';
import { GetOrderDetailsByIdResponse } from './getorderdetailsbyidresponse';
import { OrderProduct } from './order-product.model';
import { OrderDetails } from './order.model';
import { ViewportScroller } from "@angular/common";
import { Router } from '@angular/router';
import { PaymentStatus } from '../getorders/paymentstatus';

@Component({
    selector: 'app-getorderdetailsbyorderid',
    templateUrl: './getorderdetailsbyorderid.component.html',
    styleUrls: ['./getorderdetailsbyorderid.component.scss']
})
export class GetorderdetailsbyorderidComponent {

    id = 0;

    getOrderDetailsUrl = '/admin/get-orderdetailsbyid';

    GET_ORDERREFERENCE_ATTACHED_STOREPRODUCTS_URI = '/admin/get-orderreference-products';

    @ViewChild('customerOrderReference', { static: true }) target!: ElementRef;

    GET_SEPARATED_VALUE_URI = '/admin/isorderseparated';

    GET_ORDEREDPRODUCTS_URI = '/admin/get-orderedproducts';

    GET_ORDERED_STOREPRODUCTS_URI = '/admin/get-ordered-storeproducts';

    index = 0;

    isProductScreenVisible = false;

    optionDisabled = false;

    orders: OrderDetails[] = [];

    filteredOrder: OrderDetails[] = [];

    finishedOrders: OrderDetails[] = [];

    unfinishedOrders: OrderDetails[] = [];

    preOrderedOrders: OrderDetails[] = [];

    actualFilteringOrders: OrderDetails[] = [];

    orderedProductList: Product[] = [];

    orderStatusOptionList = [
        StatusOption.WAITING_FOR_PAYMENT,
        StatusOption.WAITING_FOR_PROCESSING,
        StatusOption.WAITING_FOR_PUBLICATION,
        StatusOption.PAYMENT_FAILED,
        StatusOption.UNDER_PACKING,
        StatusOption.UNDER_DELIVERY,
        StatusOption.BLOCKED,
        StatusOption.FINISHED,
        StatusOption.DELETED
    ];

    typeOfOrder = 'UNFINISHED';

    displayFinished = false;
    displayUnFinished = true;
    displayAllOrders = false;
    displayPreorders = false;

    order: OrderDetails = {};

    orderStatusStorePickupOptionList = [
        StatusOption.WAITING_FOR_PAYMENT,
        StatusOption.WAITING_FOR_PROCESSING,
        StatusOption.WAITING_FOR_PUBLICATION,
        StatusOption.PAYMENT_FAILED,
        StatusOption.BLOCKED,
        StatusOption.RECEIVABLE,
        StatusOption.FINISHED,
        StatusOption.DELETED
    ];

    productList: Product[] = [];

    productListForExcel: Product[] = [];

    productMap: Record<number, Product> = {};

    UPDATE_ORDER_STATUS_URI = '/order/update-status';

    EXPORT_TO_EXCEL_URI = '/order/export-to-excel';

    orderBackendUrl = '';

    isFinished = false;

    getProductRequest: GetProductRequest = {};

    exportToExcelRequest?: ExportToExcelRequest = {};

    exportToExcelOrder?: OrderExportToExcel[] = [];


    fileUrl: SafeResourceUrl = {};

    orderWithStatusOptionList?: OrderWithStatusOption[] = [];

    previousOrderStatus = '';

    storeBackendUrl = '';

    getOrderReferenceRequest: GetOrderReferenceRequest = {};

    getOrderReferenceResponse: GetOrderReferenceResponse = {};

    orderProductListForrequest: OrderProduct[] = [];

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };

    orderReference = '';

    isAllOrderProductsVisible = false;

    phoneNumberPrefix = '+36';

    private _listFilter = '';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredOrder = this.performFilter(value);
    }



    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        @Inject('STORE_BACKEND_URL') private _storeBackendUrl: string,
        private _matDialog: MatDialog,
        private readonly breakpointObserver: BreakpointObserver,
        private _toastMessageService: ToastmessageService,
        private scroller: ViewportScroller
    ) {
        this.orderBackendUrl = _orderBackendUrl;
        this.storeBackendUrl = _storeBackendUrl;
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            const lastSegmentOfUrl = window.location.href.split('/').pop();
            this.id = Number(lastSegmentOfUrl as string);

            const getOrderDetailsByIdRequest: GetOrderDetailsByIdRequest = {
                orderId: this.id
            };

            this._apiRequestService
                .sendRequest<GetOrderDetailsByIdRequest, GetOrderDetailsByIdResponse>(this.orderBackendUrl, true, getOrderDetailsByIdRequest, true, this.getOrderDetailsUrl)
                .subscribe((response: GetOrderDetailsByIdResponse) => {
                    this.orders = response.orderDetailsList as OrderDetails[];
                    this.orderReference = response.orderReference as string;
                    this.orders.forEach((order, index) => {
                        order.previousStatus = order.status;
                        order.customerPhone = this.phoneNumberPrefix + order.customerPhone;
                        let failedPaymentMessageKey = this.setFailedPaymentStatusMessageKey(order);
                        order.failedPaymentMessageKey =  failedPaymentMessageKey;
                        console.log(failedPaymentMessageKey)
                        //allOrdersList
                        if (order.taxNumber) {
                            this.index = index;
                        }
                        if (order.status === 'FINISHED') {
                            this.finishedOrders.push(order);
                        }
                        if (order.preOrder && order.status !== 'FINISHED') {
                            this.preOrderedOrders.push(order);
                        }
                        if (order.status !== 'FINISHED') {
                            this.unfinishedOrders.push(order);

                        }

                        this.filteredOrder = this.unfinishedOrders;
                    });

                });
        } else if (!sessionStorage.getItem('token')) {
            void this._router.navigateByUrl('/');
        }
    }

    logout() {
        sessionStorage.clear();
        void this._router.navigateByUrl('/');
    }

    performFilter(filterBy: string): OrderDetails[] {
        filterBy = filterBy.toLocaleLowerCase();
        if (this.displayAllOrders) {
            this.actualFilteringOrders = this.orders;
        } else if (this.displayFinished) {
            this.actualFilteringOrders = this.finishedOrders;
        } else if (this.displayUnFinished) {
            this.actualFilteringOrders = this.unfinishedOrders;
        } else if (this.displayPreorders) {
            this.actualFilteringOrders = this.preOrderedOrders;
        }
        return this.actualFilteringOrders.filter(
            (order: OrderDetails) =>
                order.order?.orderReference?.toLocaleLowerCase().includes(filterBy) ||
                order.lastName?.toLocaleLowerCase().includes(filterBy) ||
                order.customerEmail?.toLocaleLowerCase().includes(filterBy) ||
                order.firstName?.toLocaleLowerCase().includes(filterBy) ||
                order.insertedTimestampp?.toLocaleLowerCase().includes(filterBy) ||
                order.status?.toLocaleLowerCase().includes(filterBy) ||
                order.orderDetailReference?.toLocaleLowerCase().includes(filterBy)
        );
    }

    setBackWholeOrder() {
        this.orders = JSON.parse(sessionStorage.getItem('orders') as string) as OrderDetails[];
    }

    unfinished() {
        this.displayUnFinished = true;
        this.displayFinished = false;
        this.displayAllOrders = false;
        this.displayPreorders = false;
        this.filteredOrder = this.unfinishedOrders;
    }

    allOrders() {
        this.displayUnFinished = false;
        this.displayFinished = false;
        this.displayAllOrders = true;
        this.displayPreorders = false;
        this.filteredOrder = this.orders;
    }

    finished() {
        this.displayUnFinished = false;
        this.displayFinished = true;
        this.displayAllOrders = false;
        this.displayPreorders = false;
        this.filteredOrder = this.finishedOrders;
    }

    preorders() {
        this.displayUnFinished = false;
        this.displayFinished = false;
        this.displayAllOrders = false;
        this.displayPreorders = true;
        this.filteredOrder = this.preOrderedOrders;
    }

    sendDataToGenerateExcel() {
        let idList: Array<number> = [];
        let counter = 0;
        this.filteredOrder.forEach((filteredOrder) => {
            filteredOrder.storeOrderedProductList!.forEach((orderProduct) => {
                idList.push(orderProduct.id!);
            });

            this.getProductRequest = {
                productIdList: idList
            };
            this._apiRequestService
                .sendRequest<GetProductRequest, GetProductResponse>(
                    this.storeBackendUrl,
                    true,
                    this.getProductRequest,
                    true,
                    this.GET_ORDEREDPRODUCTS_URI
                )
                .subscribe((response: GetProductResponse) => {
                    const productResponse = response as GetProductResponse;

                    this.productList = productResponse.storeProducts as Product[];
                    let title: string[] = [];
                    this.productList.forEach((product) => {
                        title.push(product.title!);
                    });
                    this.exportToExcelOrder?.push(
                        new OrderExportToExcel(
                            filteredOrder.id,
                            filteredOrder.customerAddress,
                            filteredOrder.customerEmail,
                            filteredOrder.customerSettlement,
                            filteredOrder.customerZipCode,
                            filteredOrder.customerPhone,
                            filteredOrder.companyName,
                            filteredOrder.firstName,
                            filteredOrder.lastName,
                            filteredOrder.deliveryAddress,
                            filteredOrder.deliveryOption,
                            filteredOrder.deliverySettlement,
                            filteredOrder.deliveryZipCode,
                            filteredOrder.customerOrderReference,
                            filteredOrder.paymentOption,
                            filteredOrder.status,
                            filteredOrder.taxNumber,
                            filteredOrder.totalPrice,
                            filteredOrder.insertedTimestampp,
                            title
                        )
                    );
                    //we need a counter, because normal typescript index gives wrong result because of subscribe indexes are not in order...
                    counter++;

                    if (counter === this.filteredOrder.length) {
                        this.exportToExcel();
                    }
                });
            idList = [];
        });
    }

    exportToExcel() {
        this.exportToExcelRequest = {
            filteredOrder: this.exportToExcelOrder
        };

        this._apiRequestService
            .sendRequestBlob(this.orderBackendUrl, true, this.exportToExcelRequest, true, this.EXPORT_TO_EXCEL_URI)
            .subscribe((blobData: any) => {
                const now = new Date();
                var blob = new Blob([blobData], { type: 'application/vnd.ms-excel' });
                const url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.setAttribute('target', 'blank');
                a.href = url;
                a.download =
                    'order_' +
                    now.toLocaleDateString('en-us', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        timeZone: 'UTC'
                    });
                +'.xls';

                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            });

        this.exportToExcelOrder = [];
    }

    loadConfirmOrderStatusPopup(order: OrderDetails, enterAnimationDuration: string, exitAnimationDuration: string) {
        const isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
        this.dialogConfiger.data = order;
        const popupDialog = this._matDialog.open(ConfirmStatusPopupComponent, {
            data: order,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw'
        });
        const smallDialogSubscription = isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '70vh');
            } else {
                popupDialog.updateSize('calc(50% - 50px)', '');
            }
        });
        popupDialog.afterClosed().subscribe(() => {
            smallDialogSubscription.unsubscribe();
        });
    }

    public onClick(orderDetailReference: string): void {
        this._router.navigateByUrl('getorders/' + this.id + '#' + orderDetailReference);
        this.isProductScreenVisible = false;
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 5000);
    }
    public onClickk(): void {
        //this._router.navigateByUrl('/getorders#'+this.orderReference);
        sessionStorage.setItem('orderReference', JSON.stringify(this.orderReference));
        this._router.navigateByUrl('getorders');
    }

    setFailedPaymentStatusMessageKey(orderDetail: OrderDetails) {
        let failedPaymentMessageKey = '';
        if (orderDetail.failedPaymentMessageKey == null && orderDetail.paymentOption === 'SIMPLE') {
            if (orderDetail.status === 'WAITING_FOR_PAYMENT' || orderDetail.status === 'PAYMENT_FAILED') {
                failedPaymentMessageKey = 'FAIL'

            }
            else {
                failedPaymentMessageKey = 'SUCCESS'
            }
        }
        if(orderDetail.paymentOption === 'CASH') {
            failedPaymentMessageKey = 'PAY_ON_STORE'
        }
        return failedPaymentMessageKey;

    }

}
