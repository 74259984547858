import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService, Void } from '@prekog/pw-common';
import { EmailTemplate } from './emailtemplate';
import { GetNewsLetterResponse } from './getnewsletterresponse';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { UpdateEmailTemplateRequest } from './updateemailtemplaterequest';
import { UpdateEmailTemplateResponse } from './updateemailtemplateresponse';
import { GetSubscribedRegisteredCustomersResponse } from './getsubscribedregisteredcustomersresponse';
import { SendNewsLetterRequest } from './sendnewsletterrequest';
import { Template } from '../registeredcustomers/template';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

    htmlForm?: FormGroup;

    emailTemplate?: EmailTemplate;

    getNewsLetterUrl = '/admin/getnewsletter';

    updateEmailTemplateUrl = '/admin/updateemailtemplate';

    sendNewsLetterUrl = '/admin/sendemailtomultiplerecepients';

    emailUrl = '';

    newsLetterForm?: FormGroup;

    getSubscribedRegisteredCustomersEmailAddressesUrl = '/getsubscribedregisteredcustomers'

    loginUrl = '';

    constructor(
        private _router: Router,
        private _fb: FormBuilder,
        @Inject('EMAIL_BACKEND_URL') private _emailUrl: string,
        private _apiRequestService: ApiRequestService,
        private _toastMessageService: ToastmessageService,
        private _translateService: TranslationService,
        @Inject('AUTH_BACKEND_URL') private _loginUrl: string,
    ) {
        this.emailUrl = _emailUrl;
        this.loginUrl = _loginUrl;
        this.createHtmlForm();
        this.createNewsLetterForm();
    }
    ngOnInit(): void {
        this.getNewsLetter();
    }

    createHtmlForm() {
        this.htmlForm = this._fb.group({
            html: ['', [Validators.required]],

        });
    }

    createNewsLetterForm() {
        this.newsLetterForm = this._fb.group({
            subject: ['', [Validators.required]]
        });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    getNewsLetter() {
        this._apiRequestService
            .sendRequest<{}, GetNewsLetterResponse>(this.emailUrl, true, {}, true, this.getNewsLetterUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate(httpError.errorMessage!),
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response) => {
                this.emailTemplate = response.emailTemplate as EmailTemplate;
                this.dismissError();
            });
    }

    updateEmailTemplate() {

        this.collectProductData();

        const updateEmailTemplateRequest: UpdateEmailTemplateRequest = {
            emailTemplate: this.emailTemplate
        }
        this._apiRequestService
            .sendRequest<UpdateEmailTemplateRequest, UpdateEmailTemplateResponse>(this.emailUrl, true, updateEmailTemplateRequest, true, this.updateEmailTemplateUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate(httpError.errorMessage!),
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(
                    TOAST_STATE.success,
                    this._translateService.translate(response.responseMessage!),
                    MAT_ICON.success
                );
                this.dismissError();
                setTimeout(() => { window.location.reload() }, 3000);
            });
    }

    collectProductData() {
        const templateName = this.emailTemplate?.templateName;
        this.emailTemplate = {
            subject: this.newsLetterForm?.controls['subject'].value as string | undefined,
            message: this.htmlForm?.controls['html'].value as string | undefined,
            templateName: templateName

        }
    }

    sendNewsLetter(emailList: Array<string>) {
        const sendNewsLetterRequest: SendNewsLetterRequest = {
            emailList: emailList,
            template: Template.NEWSLETTER
        }

        this._apiRequestService
            .sendRequest<SendNewsLetterRequest, {}>(this.emailUrl, true, sendNewsLetterRequest, true, this.sendNewsLetterUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate(httpError.errorMessage!),
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(
                    TOAST_STATE.success,
                    this._translateService.translate('Sikeres hírlevél küldés!'),
                    MAT_ICON.success
                );
                this.dismissError();
            });
    }

    sendNewsLetterToCustomers() {
        this._apiRequestService
            .sendRequest<{}, GetSubscribedRegisteredCustomersResponse>(this.loginUrl, true, {}, true, this.getSubscribedRegisteredCustomersEmailAddressesUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate(httpError.errorMessage!),
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response) => {
                const emailList: Array<string> = response.emailList!;
                this.sendNewsLetter(emailList);
            });
    }
}
