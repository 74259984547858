<div class="contact-content">
    <h2>HÍRLEVÉL</h2>
    <div class="contact-form">
        <div class="form-not-required">
            <div class="field-container">
                <div class="field">
                    <div class="info">
                        <pw-fileexplorer></pw-fileexplorer>
                    </div>
                    <div class="data">
                        <app-tinymcehtmleditor
                            [html]="emailTemplate?.message!"
                            [htmlForm]="htmlForm"
                            id="html"
                            class="form-control"
                            type="text"
                            required
                            ngDefaultControl
                        ></app-tinymcehtmleditor>
                    </div>
                </div>
            </div>
            <form [formGroup]="newsLetterForm!" novalidate>
                <div class="field-container-subject">
                    <div class="field-subject">
                        <b>Email tárgya</b>
                        <div class="data-subject">
                            <input
                                [ngModel]="emailTemplate?.subject"
                                formControlName="subject"
                                id="subtitle"
                                class="form-control"
                                type="text"
                                required
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="submit-button-container">
            <div class="submit-button">
                <button (click)="updateEmailTemplate()" type="submit">Hírlevél módosítása</button>
                <button (click)="sendNewsLetterToCustomers()" type="submit">Hírlevél küldése</button>
            </div>
        </div>
    </div>
</div>
