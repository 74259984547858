import { SendEmailToMultipleRecepientsRequest } from './sendEmailToMultipleRecepientsRequest';
import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestService } from '@prekog/pw-common';
import { PersonalData } from './personaldata';
import { RegisteredCustomersResponse } from './registeredcustomersresponse';
import { Router } from '@angular/router';
import { Template } from './template';

@Component({
    selector: 'app-registeredcustomers',
    templateUrl: './registeredcustomers.component.html',
    styleUrls: ['./registeredcustomers.component.scss']
})
export class RegisteredcustomersComponent implements OnInit {
    loginBaseUrl = '';

    getRegisteredCustomersUrl = '/getregisteredcustomers';

    personalData?: PersonalData[];

    filteredPersonalData?: PersonalData[];

    registeredCustomersResponse?: RegisteredCustomersResponse;

    searchText = '';

    phoneNumberPrefix='+36';

    sendNewsLetterUrl = '/admin/sendemailtomultiplerecepients';

    emailUrl = '';

    emailList:Array<string> = [];

    constructor(
        private _router: Router,
        @Inject('AUTH_BACKEND_URL') private _loginUrl: string,
        @Inject('EMAIL_BACKEND_URL') private _emailUrl: string,
        private _apiRequestService: ApiRequestService
    ) {
        this.loginBaseUrl = _loginUrl;
        this.emailUrl = _emailUrl;
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService.sendRequest(this.loginBaseUrl, true, {}, true, this.getRegisteredCustomersUrl).subscribe((response) => {
                this.registeredCustomersResponse = response as RegisteredCustomersResponse;
                this.personalData = this.registeredCustomersResponse.clientList;
                this.personalData?.forEach((personalData)=> {
                    personalData.phoneNumber = this.phoneNumberPrefix+personalData.phoneNumber;
                    if(personalData.subscribedForNewsLetter) {
                        this.emailList.push(personalData.email!);
                    }
                })
                this.filteredPersonalData = this.personalData;
            });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    sendNewsLetter() {
        const sendEmailToMultipleRecepientsRequest:SendEmailToMultipleRecepientsRequest = {
            recipient:this.emailList,
            template:Template.NEWSLETTER
        }

        this._apiRequestService.sendRequest(this.emailUrl, true, sendEmailToMultipleRecepientsRequest, true, this.sendNewsLetterUrl).subscribe((response) => {


        });
    }
}
