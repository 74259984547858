import { AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { ApiRequestService, HttpError, MAT_ICON, MatdialogService, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { OrderWithDate } from './orderwithdate';
import { GetOrdersResponse } from '../get-orders-response.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FindOrderResponse } from './findorderresponse';
import { FindOrderRequest } from './findorderrequest';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderStatus } from '../order-status.model';
import { StatusOption } from '../statusoption';
import { DeliveryOption } from './deliveryoption';
import { OrderDate } from './orderdate';
import { PaymentStatus } from './paymentstatus';
import { catchError, map, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetProductsResponse } from '../../products/getproductsresponse';
import { Material } from '../../products/material';
import { FilterComponent } from './filter/filter.component';
import { FilterOrdersRequest } from './filterOrdersRequest';
import { FilterOrdersResponse } from './filterOrdersResponse';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { OrderDetails } from '../getorderdetailsbyorderid/order.model';
import { GetProductRequest } from '../get-product-request';
import { GetProductResponse } from '../get-product-response';
import { Product } from '../product.model';
import { ConfirmStatusPopupComponent } from '../../confirm-status-popup/confirm-status-popup.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ExportToExcelRequest } from '../export-to-excel-request';
import { OrderExportToExcel } from '../order-export-to-excel';
import { SafeResourceUrl } from '@angular/platform-browser';
import { OrderWithStatusOption } from '../order-with-statusoption';
import { GetOrderReferenceRequest } from '../getorderreferencerequest';
import { GetOrderReferenceResponse } from '../getorderreferenceresponse';
import { OrderProduct } from '../order-product.model';
import { GetOrderDetailsByIdRequest } from '../getorderdetailsbyorderid/getorderdetailsbyidrequest';
import { GetOrderDetailsByIdResponse } from '../getorderdetailsbyorderid/getorderdetailsbyidresponse';


@Component({
    selector: 'app-getorders',
    templateUrl: './getorders.component.html',
    styleUrls: ['./getorders.component.scss']
})
export class GetordersComponent implements OnInit {


    storeExistingProductsURI = '/admin/get-products';

    orderFilterUrl = '/admin/filter-order';

    products: Material[] = [];

    storeBaseUrl = '';

    orderBackendUrl = '';

    dateForm?: FormGroup;

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };

    filteredOrder: OrderWithDate[] = [];

    orders: OrderWithDate[] = [];

    findOrderByOrderReferenceUrl = '/admin/find-order-by-orderreference';

    getOrdersUrl = '/admin/get-orderswith';

    @ViewChild('startDateElementInput') startDateElementInput!: ElementRef;
    startDateElement = '';

    @ViewChild('endDateElementInput') endDateElementInput!: ElementRef;
    endDateElement = '';

    disableDate = false;

    isSearchAboutOrderReference = true;

    isSearchActive = false;

    selected = 'Vissza az előző heti rendelésekhez';

    searchText = '';

    isPaymentSuccessful = false;

    ordersAccordingToStatuses = [
        StatusOption.ALL,
        StatusOption.FINISHED,
        StatusOption.UNFINISHED,
        StatusOption.PREORDER
    ]
    ordersType = [
        OrderDate.LAST_TWO_MONTHES,
        OrderDate.LAST_MONTH
    ]
    ordersAccordingTodeliveryOptions = [
        DeliveryOption.FOXPOST,
        DeliveryOption.STORE_PICKUP,
        DeliveryOption.MPL,
        DeliveryOption.ALL
    ]

    ordersAccordingToOrderDate = [
        OrderDate.LAST_TWO_MONTHES,
        OrderDate.LAST_MONTH
    ]

    ordersAccordingToPaymentStatus = [
        PaymentStatus.SUCCESSFUL,
        PaymentStatus.PAY_ON_STORE,
        PaymentStatus.FAIL,
        PaymentStatus.ALL
    ]

    isPreorder = false;

    status = StatusOption.ALL;

    deliveryOption = DeliveryOption.ALL;

    orderDate = OrderDate.LAST_TWO_MONTHES;

    productId = 0;

    paymentStatus = PaymentStatus.ALL;

    nofilter = false;
    orderReference: any;

    constructor(
        private _fb: FormBuilder,
        private _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        private _toastMessageService: ToastmessageService,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _translateService: TranslationService,
        private _matDialogService: MatdialogService,
        private scroller: ViewportScroller,
        private _activatedRoute: ActivatedRoute,
        @Inject(LOCALE_ID) private locale: string,
        private _matDialog: MatDialog,
        private readonly breakpointObserver: BreakpointObserver,
    ) {
        this.orderBackendUrl = _orderBackendUrl;
        this.storeBaseUrl = _storeBaseUrl;

    }

    ngOnInit(): void {

        if (sessionStorage.getItem('token')) {
            if (sessionStorage.getItem('orderReference')) {
                this.orderReference = JSON.parse(sessionStorage.getItem('orderReference') as string) as string;
            }
            this.getProducts();
            if (sessionStorage.getItem('filteredOrder')) {
                this.filteredOrder = JSON.parse(sessionStorage.getItem('filteredOrder') as string) as OrderWithDate[];
                //sessionStorage.removeItem('filteredOrder');
            }
            if (sessionStorage.getItem('status')) {
                this.status = JSON.parse(sessionStorage.getItem('status') as string) as StatusOption;
            }
            if (sessionStorage.getItem('deliveryOption')) {
                this.deliveryOption = JSON.parse(sessionStorage.getItem('deliveryOption') as string) as DeliveryOption;
            }
            if (sessionStorage.getItem('orderDate')) {
                this.orderDate = JSON.parse(sessionStorage.getItem('orderDate') as string) as number;
            }
            if (sessionStorage.getItem('productId')) {
                this.productId = JSON.parse(sessionStorage.getItem('productId') as string) as number;
            }
            if (sessionStorage.getItem('paymentStatus')) {
                this.paymentStatus = JSON.parse(sessionStorage.getItem('paymentStatus') as string) as PaymentStatus;
            }
            else {
                this._apiRequestService
                    .sendRequest<{}, GetOrdersResponse>(this.orderBackendUrl, true, {}, true, this.getOrdersUrl)
                    .subscribe((response) => {
                        this.orders = response.orderList as OrderWithDate[];
                        this.filteredOrder = this.orders;
                    });
            }
        }

        else {
            this._router.navigateByUrl('/');
        }

    }

    findOrderBy() {

        if (this.searchText === '') {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.warning,
                "Kérem adja meg a rendelési azonosítót, vagy nevet!",
                MAT_ICON.warning
            );
            this.dismissError();
        }
        else {
            this.isSearchActive = true;
            this.filteredOrder = [];
            this.orders = [];
            this.findOrder();
        }
    }

    findOrder() {
        const findOrderRequest = {
            searchPiece: this.searchText,
            startDate: this.startDateElement,
            endDate: this.endDateElement,
            isSearchAboutOrderReference: this.isSearchAboutOrderReference

        }

        this._apiRequestService
            .sendRequest<FindOrderRequest, FindOrderResponse>(this.orderBackendUrl, true, findOrderRequest, true, this.findOrderByOrderReferenceUrl)
            .subscribe((response) => {
                const orderWithInsertedTimeStamp = response.orderWithDate as OrderWithDate[];
                if (orderWithInsertedTimeStamp !== null) {
                    //this.filteredOrder.push(orderWithInsertedTimeStamp);
                    this.orders = [...orderWithInsertedTimeStamp];
                    this.filteredOrder = this.orders;
                }
                else {
                    this._toastMessageService.displayToastMessage(
                        TOAST_STATE.success,
                        response.responseMessage!,
                        MAT_ICON.success
                    );
                    this.dismissError();

                }
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }


    collectDates() {
        let startDate = this.startDateElement;
        let endDate = this.endDateElement;
        console.log(endDate)
        this.startDateElement = startDate?.replace(/\D/g, '-') as string;
        this.endDateElement = endDate?.replace(/\D/g, '-') as string;

    }

    onChangeListfilter() {
        if (this.searchText !== '') {
            this.disableDate = true;
            console.log(this.disableDate)
        }
        else {
            this.disableDate = false;
            console.log(this.disableDate)
        }
    }

    searchAboutOnChangeOnDates() {
        this.isSearchAboutOrderReference = false;
        this.searchText = '';
    }

    searchAboutOnChangeOnOrderReference() {
        this.isSearchAboutOrderReference = true;
        this.startDateElementInput.nativeElement.value = '';
        this.endDateElementInput.nativeElement.value = '';
        this.startDateElement = '';
        this.endDateElement = '';

    }

    reload() {
        window.location.reload();
    }

    findOrderDetailsByOrderId(orderId: number) {
        this._router.navigateByUrl('/getorders/' + String(orderId!))
        if (this.isSearchActive) {
            sessionStorage.setItem('filteredOrder', JSON.stringify(this.filteredOrder));
        }




    }

    getProducts() {
        this._apiRequestService.sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage), MAT_ICON.error);
                        this.dismissError();


                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                const products = response as GetProductsResponse;
                this.products = products.productList as Material[];
            });
    }

    displayFilterPanelPopup() {
        this._matDialogService.openPopupWithOutData(FilterComponent);
    }

    filter() {
        if (this.isOrderListRefreshable()) {
            this.refreshPage();
        }
        else {

            const FilterOrdersRequest: FilterOrdersRequest = {
                orderStatus: this.status,
                deliveryOption: this.deliveryOption,
                timeInterval: this.orderDate,
                productId: this.productId,
                failedPaymentMessageKey: this.paymentStatus
            }

            this._apiRequestService
                .sendRequest<FilterOrdersRequest, FilterOrdersResponse>(this.orderBackendUrl, true, FilterOrdersRequest, true, this.orderFilterUrl)
                .subscribe((response) => {
                    const filteredOrder = response.filteredOrderList as OrderWithDate[];
                    if (filteredOrder.length > 0) {
                        sessionStorage.setItem('filteredOrder', JSON.stringify(this.filteredOrder));
                        sessionStorage.setItem('status', JSON.stringify(this.status));
                        sessionStorage.setItem('deliveryOption', JSON.stringify(this.deliveryOption));
                        sessionStorage.setItem('orderDate', JSON.stringify(this.orderDate));
                        sessionStorage.setItem('productId', JSON.stringify(this.productId));
                        sessionStorage.setItem('paymentStatus', JSON.stringify(this.paymentStatus));
                        sessionStorage.setItem('filteredOrder', JSON.stringify(filteredOrder));
                        window.location.reload();
                    }
                    else {
                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.warning,
                            "Nincs a keresési feltételnek megfelelő rendelés",
                            MAT_ICON.warning
                        );
                        this.dismissError();
                    }

                });
        }
    }

    refreshPage() {
        if (sessionStorage.getItem('filteredOrder')) {
            sessionStorage.removeItem('filteredOrder');
        }
        if (sessionStorage.getItem('status')) {
            sessionStorage.removeItem('status');
        }
        if (sessionStorage.getItem('deliveryOption')) {
            sessionStorage.removeItem('deliveryOption');
        }
        if (sessionStorage.getItem('orderDate')) {
            sessionStorage.removeItem('orderDate');
        }
        if (sessionStorage.getItem('productId')) {
            sessionStorage.removeItem('productId');
        }
        if (sessionStorage.getItem('paymentStatus')) {
            sessionStorage.removeItem('paymentStatus');
        }
        window.location.reload();
    }

    isOrderListRefreshable(): boolean {
        const isRefreshable: boolean = this.status == StatusOption.ALL
            &&
            this.deliveryOption == DeliveryOption.ALL
            &&
            this.paymentStatus == PaymentStatus.ALL
            &&
            this.productId == 0
            &&
            this.orderDate == OrderDate.LAST_TWO_MONTHES
        console.log(isRefreshable)
        return isRefreshable;
    }

    public scrollToFragment(): void {
        this._router.navigate([], { fragment: this.orderReference });
        document.getElementById(this.orderReference)!.style.color = "red";
        window.location.hash = '';
    }

}
