<div class="filter-container">
    <div class="mat-dialog-actions" mat-dialog-actions [align]="selectedAlign">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
    <div class="filter">
        <div class="filter-elements">
            <div class="select-container">
                <span for="statusOption">RENDELÉS ÁLLAPOTA SZERINT: </span>
                <select
                    [(ngModel)]="status"
                    [ngStyle]="{ 'font-size': '12px', cursor: 'pointer' }"
                    name="statusOption"
                    id="statusOption"
                    placeholder="Válasszon státuszt!"
                >
                    <option *ngFor="let statusOption of ordersAccordingToStatuses" [value]="statusOption">
                        {{ 'admin.' + statusOption | translate }}
                    </option>
                </select>
            </div>
            <div class="select-container">
                <span for="statusOption">SZÁLLÍTÁSI MÓD SZERINT: </span>
                <select [(ngModel)]="deliveryOption" [ngStyle]="{ 'font-size': '12px', cursor: 'pointer' }" name="cars" id="cars">
                    <option *ngFor="let deliveryOption of ordersAccordingTodeliveryOptions" [value]="deliveryOption">
                        {{ 'admin.' + deliveryOption | translate }}
                    </option>
                </select>
            </div>
            <div class="select-container">
                <span for="statusOption">MEGRENDELÉS DÁTUMA SZERINT: </span>
                <select [(ngModel)]="orderDate" [ngStyle]="{ 'font-size': '12px', cursor: 'pointer' }" name="cars" id="cars">
                    <option *ngFor="let orderDate of ordersAccordingToOrderDate" [value]="orderDate">
                        {{ 'admin.' + orderDate | translate }}
                    </option>
                </select>
            </div>
            <div class="select-container">
                <span for="statusOption">KIADVÁNY SZERINT: </span>
                <select [(ngModel)]="productId" [ngStyle]="{ 'font-size': '12px', cursor: 'pointer' }" name="cars" id="cars">
                    <option value="0">Összes</option>
                    <option *ngFor="let product of products" [value]="product.id" value="volvo">
                        {{ product.author + ' ' + product.title }}
                    </option>
                </select>
            </div>
            <div class="select-container">
                <span for="statusOption">KIFIZETÉS STÁTUSZA SZERINT: </span>
                <select [(ngModel)]="paymentStatus" [ngStyle]="{ 'font-size': '12px', cursor: 'pointer' }" name="cars" id="cars">
                    <option *ngFor="let paymentStatus of ordersAccordingToPaymentStatus" [value]="paymentStatus">
                        {{ 'admin.' + paymentStatus | translate }}
                    </option>
                </select>
            </div>
            <!-- <div class="select-container">
                <section class="example-section">
                    <mat-checkbox formControlName="updates" class="example-margin" [checked]="isPreorder">ELŐRENDELÉS</mat-checkbox>
                </section>
            </div>-->
            <div class="filter-name"></div>
            <div class="filter-radio"></div>
            <div class="button-container">
                <button (click)="filter()">KERESÉS</button>
            </div>
        </div>
    </div>
</div>
